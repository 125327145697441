.SellTradeHome-module__container {
  max-width: 965px;
  background: #e3b643;
  background: linear-gradient(179deg, #fff2e3 0%, #ffebd4 100%);
  margin: 0 auto;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 48px;
  margin-top: 65px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.SellTradeHome-module__container > div > strong {
  color: #154f9c;
  font-size: 28px;
  line-height: 42px;
}

.SellTradeHome-module__container > div > p {
  margin-top: 16px;
  color: #154f9c;
  font-size: 20px;
  line-height: 32px;
}

.SellTradeHome-module__container > div > a {
  display: inline-flex;
  width: 228px;
  height: 52px;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  font-weight: bold;
  border-radius: 4px;
}

.SellTradeHome-module__container > img {
  max-width: 377px;
}
body[dir="rtl"] .SellTradeHome-module__container > img {
  margin-left: 90px;
}
body[dir="ltr"] .SellTradeHome-module__container > img {
  margin-right: 90px;
}
.SellTradeHome-module__container > img {
  height: 80px;
  width: 250px;
}
.SellTradeHome-module__upArrow,
.SellTradeHome-module__downArrow {
  height: auto !important;
  width: auto !important;
  margin: 0 !important;
}

body[dir="rtl"] .SellTradeHome-module__upArrow {
  position: absolute;
  bottom: 0;
  left: 0;
}
body[dir="ltr"] .SellTradeHome-module__upArrow {
  position: absolute;
  bottom: 0;
  right: 0;
  transform: scaleX(-1);
}
body[dir="rtl"] .SellTradeHome-module__downArrow {
  position: absolute;
  top: 0;
  right: 0;
}
body[dir="ltr"] .SellTradeHome-module__downArrow {
  position: absolute;
  top: 0;
  left: 0;
  transform: scaleX(-1);
}

@media screen and (max-width: 1024px) {
  .SellTradeHome-module__container {
    margin-top: 0;
    flex-direction: column;
    max-width: 90%;
    padding: 32px 30px;
    text-align: center;
  }
  .SellTradeHome-module__container > img {
    /* max-width: 80%; */
    margin: 0 auto !important;
    height: 62px;
    width: 192px;
    z-index: 1;
  }
  .SellTradeHome-module__container > div > strong {
    font-size: 28px;
    line-height: 40px;
    margin-top: 16px;
  }
  .SellTradeHome-module__container > div > p {
    font-size: 16px;
    line-height: 32px;
    text-align: center;
  }
  .SellTradeHome-module__container > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .SellTradeHome-module__container > div > a {
    font-size: 16px;
    line-height: 24px;
    z-index: 1;
  }
}
